import React from "react";
import { Link } from "react-router-dom";

const PricingArea = () => {
	return (
		<section className="pricing-area pb-90 pt-90">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-10">
						<div className="section-title title-style-two text-center white-title mb-50">
							<h2 className="title">
								<img src="/img/roadmap.png" alt="" />
							</h2>
						</div>
					</div>
				</div>

				<div className="pricing-item-wrap">
					<div className="tab-content" id="myTabContent">
						<div
							className="tab-pane show active"
							id="month"
							role="tabpanel"
							aria-labelledby="month-tab"
						>
							<div className="row g-0 align-items-center justify-content-center">
								<div className="col-lg-5 col-md-12 mr-20">
									<div className="pricing-item">
										<div className="pricing-icon">
											<img src="/img/icon/pricing_icon01.png" alt="" />
										</div>
										<div className="pricing-top">
											<h2 className="title">PHASE 1</h2>
											<p>Stealth Launch and Community Building</p>
										</div>
										<div className="pricing-list">
											<ul className="list-wrap">
												<li>
													<i className="far fa-check"></i>Stealth Launch of SSI
													on Solana
												</li>
												<li>
													<i className="far fa-check"></i>Establish social media
													channels
												</li>
												<li>
													<i className="far fa-check"></i>Launch Official
													Telegram group
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-lg-5 col-md-12 mr-20">
									<div className="pricing-item">
										<div className="pricing-icon">
											<img src="/img/icon/pricing_icon02.png" alt="" />
										</div>
										<div className="pricing-top">
											<h2 className="title">PHASE 2</h2>
											<p>
												Market Capitalization Milestones and Burning Schedule
											</p>
										</div>
										<div className="pricing-list">
											<ul className="list-wrap">
												<li>
													<i className="far fa-check"></i>Burn 10% at $1M MC
												</li>
												<li>
													<i className="far fa-check"></i>Burn additional 15% at
													$10M MC
												</li>
												<li>
													<i className="far fa-check"></i>Burn another 25% at
													$100M MC
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-lg-5 col-md-12 mr-20">
									<div className="pricing-item">
										<div className="pricing-icon">
											<img src="/img/icon/pricing_icon03.png" alt="" />
										</div>
										<div className="pricing-top">
											<h2 className="title">PHASE 3</h2>
											<p>Expansion and Partnerships</p>
										</div>
										<div className="pricing-list">
											<ul className="list-wrap">
												<li>
													<i className="far fa-check"></i>Explore partnerships
													in Solana Ecosystem
												</li>
												<li>
													<i className="far fa-check"></i>Expand SSI use cases
												</li>
												<li>
													<i className="far fa-check"></i>Integrate with
													wallets/platforms
												</li>
												<li>
													<i className="far fa-check"></i>Implement community
													governance
												</li>
												<li>
													<i className="far fa-check"></i>Maintain marketing
													campaigns
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-lg-5 col-md-12 mr-20">
									<div className="pricing-item">
										<div className="pricing-icon">
											<img src="/img/icon/pricing_icon04.png" alt="" />
										</div>
										<div className="pricing-top">
											<h2 className="title">PHASE 4</h2>
											<p>Continued Growth and Sustainability</p>
										</div>
										<div className="pricing-list">
											<ul className="list-wrap">
												<li>
													<i className="far fa-check"></i>Monitor and adapt to
													market trends
												</li>
												<li>
													<i className="far fa-check"></i>Engage with the
													community for feedback and suggestions
												</li>
												<li>
													<i className="far fa-check"></i>Explore additional
													opportunities for token utility
												</li>
												<li>
													<i className="far fa-check"></i>Foster a positive and
													inclusive community cultue
												</li>
												<li>
													<i className="far fa-check"></i>Ensure transparency
													and accountability in project operations
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PricingArea;
