import React from "react";
import SlickSlider from "../SlickSlider/SlickSlider";

const BrandArea = () => {
	const slick_settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		autoplay: true,
		arrows: false,
		slidesToShow: 4,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					arrows: false,
				},
			},
		],
	};

	return (
		<div className="brand-area">
			<div className="container">
				<div className="row brand-active">
					<SlickSlider settings={slick_settings}>
						{[1, 2, 3, 4].map((x) => (
							<div key={x} className="col-3 pr-30 pl-30">
								<div className="brand-item">
									<img src={`/img/brand/brand_img0${x}.png`} alt="" />
								</div>
							</div>
						))}
					</SlickSlider>
				</div>
			</div>
		</div>
	);
};

export default BrandArea;
