import React from "react";
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";

const AboutAreaTwo = () => {
	const parallax = useParallax({
		translateY: [-24, 24],
		rootMargin: 0,
	});

	return (
		<section className="about-area-two">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-12">
						<div className="about-content-two">
							<div className="section-title white-title title-style-two mb-20">
								<span className="sub-title">SHIBA SPACEMAN INU</span>
								<h2 className="title">
									We’re firm believers that the true magic of memes thrives
									within a community. Come aboard and let’s propel our community
									holders’ success straight to the moon!
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="about-shape-wrap">
				<img
					src="/img/images/h2_about_shape01.png"
					alt=""
					className="shape-one"
					ref={parallax.ref}
				/>
				<img
					src="/img/images/h2_about_shape02.png"
					alt=""
					className="shape-two"
				/>
				<img
					src="/img/images/h2_about_shape03.png"
					alt=""
					className="shape-three"
				/>
			</div>
		</section>
	);
};

export default AboutAreaTwo;
