import React from "react";
import { Link } from "react-router-dom";

const FooterTwo = () => {
	return (
		<footer>
			<div className="footer-area-two">
				<div className="container">
					<div className="footer-bottom-two">
						<div className="row align-items-center">
							<div className="col-md-6">
								<div className="copyright-text">
									<p>© 2024, SHIBA SPACEMAN INU - SSI Token.</p>
								</div>
							</div>

							<div className="col-md-6">
								<div className="footer-social-two">
									<ul className="list-wrap">
										<li className="title">Follow us</li>
										<li>
											<a href="https://t.me/ShibaSpaceman" target="_blank">
												<img src="/img/icon/footer_icon02.png" alt="" />
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterTwo;
