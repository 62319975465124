import React from "react";
import { Link } from "react-router-dom";

const BannerTwo = () => {
	return (
		<section className="banner-area-two">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-xl-6 col-lg-5">
						<div className="banner-img-two">
							<img src="/img/banner/h2_banner_img.png" alt="" />
						</div>
					</div>
					<div className="col-xl-6 col-lg-7">
						<div className="banner-content-two">
							<h2 className="title">
								<img src="/img/banner_logo.png" alt="" />
								<img src="/img/nowsolana.png" alt="" />
							</h2>
						</div>
					</div>
				</div>
			</div>

			<div className="banner-shape-wrap-two">
				<img
					src="/img/banner/h2_banner_shape01.png"
					alt=""
					className="rotateme"
				/>
				<img
					src="/img/banner/h2_banner_shape02.png"
					alt=""
					className="ribbonRotate"
				/>
				<img
					src="/img/banner/h2_banner_shape03.png"
					alt=""
					className="ribbonRotate"
				/>
				<img src="/img/banner/h2_banner_shape04.png" alt="" />
			</div>
		</section>
	);
};

export default BannerTwo;
