import React from "react";
import ContactForm from "./ContactForm";
import { Link, useLocation } from "react-router-dom";

const Contact = () => {
	return (
		<section className="contact-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<div className="section-title title-style-two white-title text-center mb-75">
							<span className="sub-title wrap">
								CONTRACT: 9sLLGFNryUeiNhLnNiukp3fJkjMmXxRVhXa4EgQceYHU
							</span>
							<span className="sub-title">
								<Link to="https://raydium.io/swap/?outputCurrency=9sLLGFNryUeiNhLnNiukp3fJkjMmXxRVhXa4EgQceYHU">
									BUY ON RAYDIUM
								</Link>
							</span>
							<h2 className="title">SHIBA SPACEMAN INU on DEXSCREEN</h2>
						</div>
					</div>
				</div>
				<div id="dexscreener-embed">
					<iframe
						title="SSI ON DEXCREEN"
						src="https://dexscreener.com/solana/7rwpxheqlbrtp7k32hzmlrg4k7jvrxc5yjruetjvvj6z?embed=1&amp;theme=dark&amp;trades=0&amp;info=0"
					></iframe>
				</div>
			</div>
		</section>
	);
};

export default Contact;
