import React, { useEffect } from "react";
import $ from "jquery";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

const HeaderTwo = () => {
	useEffect(() => {
		/*=============================================
	=     Menu sticky & Scroll to top      =
=============================================*/
		$(window).on("scroll", function () {
			var scroll = $(window).scrollTop();
			if (scroll < 245) {
				$("#sticky-header").removeClass("sticky-menu");
				$(".scroll-to-target").removeClass("open");
			} else {
				$("#sticky-header").addClass("sticky-menu");
				$(".scroll-to-target").addClass("open");
			}
		});

		//Mobile Nav Hide Show

		/*=============================================
	=          header btn active               =
=============================================*/
		$(function () {});
	}, []);

	const { pathname } = useLocation();

	const isActiveClassName = (path) => {
		return path === pathname ? "active" : "";
	};

	return (
		<>
			<header>
				<div
					id="sticky-header"
					className="menu-area menu-style-two transparent-header"
				>
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="menu-wrap">
									<nav className="menu-nav">
										<div className="logo">
											<Link to="/">
												<img src="/img/logo/w_logo.png" alt="Logo" />
											</Link>
										</div>

										<div className="navbar-wrap main-menu d-none d-lg-flex"></div>
									</nav>
								</div>

								{/* <!-- Mobile Menu  --> */}
								{/* <!-- End Mobile Menu --> */}
							</div>
						</div>
					</div>

					{/* <!-- header-contact --> */}
					{/* <!-- header-contact-end --> */}
				</div>
			</header>
		</>
	);
};

export default HeaderTwo;
